export default function footerSocials() {
  const elements = document.querySelector('.page-footer .socials')
  window.addEventListener('scroll', () => {
    const socialsPos = elements.parentNode.getBoundingClientRect()
    if(socialsPos.y - window.innerHeight < 0) {
      elements.classList.remove('sticky')
    } else {
      elements.classList.add('sticky')
    }
  })
}
import './cookies'

import Navigation from './navigation'
import footerSocials from './footerSocials'
import PureCounter from "@srexi/purecounterjs";
import expandSection from './expandSection';

footerSocials()

document.addEventListener('DOMContentLoaded', () => {
  expandSection()
})

new Navigation()
new PureCounter();

$('.header-carousel').slick({
  pauseOnFocus: false,
  pauseOnHover: false,
  autoplay: true,
  autoplaySpeed: 5000,
  prevArrow: '<button class="prevArrow"></button>',
  nextArrow: '<button class="nextArrow"></button>',
});

AOS.init({
  duration: 700,
  offset: 0
})
import { screen } from './utils'

export default class Navigation {
  constructor() {
    this.isOpened = false
    this.elNavWrapper = document.querySelector('.nav-wrapper')
    this.elBody = document.querySelector('body')
    this.elNavToggle = document.querySelector('.nav-toggle')
    this.elPageShadow = document.querySelector('.page-shadow')
    this.elArrDropdownOptions = document.querySelectorAll('.nav .dropdown > a')
    this.initEvents()
  }

  initEvents() {
    this.elNavToggle.addEventListener('click', () => {
      if(this.isOpened) this.close()
      else this.open()
    })

    this.elArrDropdownOptions.forEach(element => {
      element.addEventListener('click', (e) => {
        this.dropdownToggle(e, element)
      })
    })

    this.elPageShadow.addEventListener('click', this.close.bind(this))
  }

  open() {
    this.isOpened = true
    this.elNavWrapper.classList.add('opened')
    this.elBody.classList.add('nav-opened')
    this.elNavToggle.classList.add('active')
    this.elPageShadow.classList.add('nav-opened')
  }

  close() {
    this.isOpened = false
    this.elNavWrapper.classList.remove('opened')
    this.elBody.classList.remove('nav-opened')
    this.elNavToggle.classList.remove('active')
    this.elPageShadow.classList.remove('nav-opened')
  }

  dropdownToggle(e, element) {
    if(screen("xs", "lg")) {
      const parent = element.parentNode
      if(parent.classList.contains('expanded')) {
        parent.classList.remove('expanded')
      } else {
        this.elArrDropdownOptions.forEach(el => {
          el.parentNode.classList.remove('expanded')
        })
        parent.classList.add('expanded')
      }
      e.preventDefault()
    }
  }
}
export default function expandSection() {
  // Single element
  const elements = document.querySelectorAll('.expand-section-control')
  elements.forEach(element => {
    element.addEventListener('click', () => {
      const sectionName = element.dataset.expand
      const section = document.querySelector(`.expand-section[data-expand="${sectionName}"]`)
      section.dataset.show = (section.dataset.show === "true") ? "false" : "true";
      element.dataset.show = (element.dataset.show === "true") ? "false" : "true";
    })
  })

  // Combo elements
  const comboElements = document.querySelectorAll('.faq-combo .combo-item .title-button')
  const comboItems = document.querySelectorAll('.faq-combo .combo-item')
  comboElements.forEach(element => {
    element.addEventListener('click', () => {
      if(element.parentElement.classList.contains('active')) {
        element.parentElement.classList.remove('active')
        return
      }
      comboItems.forEach(el => {
        el.classList.remove('active')
      })
      element.parentNode.classList.add('active')
      history.pushState({}, "", "#" + element.parentNode.id)
    })

    const comboItem = element.parentNode
    if('#' + comboItem.id === window.location.hash) {
      comboItem.classList.add('active')
      const offsetTop = comboItem.getBoundingClientRect().top + document.documentElement.scrollTop
      console.log(offsetTop)
      setTimeout(() => {
        window.scrollTo({
          top: offsetTop - 180,
          behavior: 'smooth'
        })
      }, 2000);
    }
  })
}